<template>
  <div class="w-full max-h-screen bg-background flex flex-col relative">
    <page-header title="Stripe Transactions"></page-header>
    <main class="p-6 bg-background flex-1 overflow-y-scroll">
      <div class="w-full bg-white p-5 rounded shadow-md relative">
        <div class="w-full">
          <div v-if="viewState == 'Idle'">
            <vue-good-table
              :columns="columns"
              :rows="allTransactions"
              :pagination-options="paginationOpts"
              :row-style-class="markRowAsSelected"
              :sort-options="{
                enabled: true,
                initialSortBy: {field: 'received_at', type: 'desc'}
              }"
              v-if="viewState == 'Idle' && allTransactions.length > 0"
              styleClass="vgt-table condensed"              
              @on-column-filter="openColumnFilter"
              @on-sort-change="openSortChange"
              @on-row-click="onEnquiryRowSelected">
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'stripe_event'">
                  <span
                    v-if="props.row.stripe_event == 'checkout.session.completed'"
                  >Website Checkout</span>
                  <span
                    v-else-if="props.row.stripe_event == 'payment_intent.succeeded'"
                  >Manual Payment</span>
                </span>
                <span v-else>{{props.formattedRow[props.column.field]}}</span>
              </template>
            </vue-good-table>
            <!-- :sort-options="{
                  initialSortBy: { field: $store.state.openSortOptions.field, type: $store.state.openSortOptions.type }
            }"-->
            <h5
              class="text-muted text-center pt-3 pb-3"
              v-if="!allTransactions || allTransactions.length == 0"
            >No sales enquiries have been created yet</h5>
          </div>
          <div v-if="viewState == 'Loading'" class="flex items-center justify-center py-20">
            <spinner :color="'text-black'" :size="10" />
          </div>
          <div v-if="viewState == 'Error'" class="flex items-center justify-center py-10">
            <div class="bg-red-200 rounded-lg w-full px-4 py-2">
              <h2 class="text-red-600 text-xl font-bold">{{ error.status }}: Error</h2>
              <p class="text-red-800 text-lg">
                There has been an error while trying to loading the enquiries, please try again later.
                <br />If the problem persists, please contact your support team.
              </p>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
const PageHeader = () => import("@/components/PageHeader.vue");
const Spinner = () => import("@/components/Spinner.vue");
const Toggle = () => import("@/components/Toggle.vue");

export default {
  name: "stripe-transactions",
  components: {
    PageHeader,
    Spinner,
    Toggle,
  },
  data() {
    return {
      viewState: "Idle",
      error: null,
      columns: [],
      paginationOpts: {
        enabled: true,
        perPage: 20,
      },
      allTransactions: [],
    };
  },
  computed: {
    searchOptions: function () {
      return this.$store.state.enquirySearchOptions;
    },
  },
  async mounted() {
    try {
      this.viewState = "Loading";
      let transactions = await this.StripeTransactionService.getTransactions();
      this.allTransactions = this.$_.filter(transactions, (t) => !t.xero_ignore);

      this.columns = [
        {
          label: "Transaction Type",
          field: "stripe_event",
        },
        {
          label: "Date Received",
          field: "received_at",
          type: "date",
          dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
          dateOutputFormat: "dd/MM/yyyy",
        },
        {
          label: "Status",
          field: "status",
        },
        {
          label: "Xero Invoice #",
          field: "xero_invoice_number",
        },
      ];

      this.viewState = "Idle";
    } catch (err) {
      console.error(err);
      this.error = err;
      this.viewState = "Error";
    }
  },
  methods: {
    sortFollowUp: function (x, y) {
      if (y == null) {
        return -1;
      }
      return x < y ? -1 : x > y ? 1 : 0;
    },
    onEnquiryRowSelected: function (selectedRow) {
      this.$store.dispatch(
        "setLastSelectedTransaction",
        selectedRow.row.stripe_event_id
      );
      let transaction = selectedRow.row;
      this.$router.push({
        name: "view-stripe-transaction",
        params: { transactionId: selectedRow.row.stripe_event_id },
      });
    },
    markRowAsSelected: function (row) {
      return this.$store.state.lastSelectedTransaction == row.stripe_event_id
        ? "bg-green-300"
        : "";
    },

    openSortChange(params) {
      this.$store.dispatch("setOpenSortOptions", params[0]);
    },
    openColumnFilter(params) {
      let param = {
        status_detail: params.columnFilters.status_detail,
        default_agent_name: params.columnFilters["default_agent.name"],
      };
      this.$store.dispatch("setOpenFilterOptions", param);
    },
  },
};
</script>

<style >
.vgt-table th.filter-th {
  background: white;
}
.vgt-right-align {
  text-align: center;
}
</style>
